import React from 'react';
import { useResponsive } from '../../hooks/use_responsive';
import { WebAnalytics } from '../../lib/web_analytics';
import { RoutePaths } from '../../routes';
import { OmniaLinkButton } from '../omnia_button/omnia_link_button';
import { OmniaLink } from '../omnia_link/omnia_link';
import SvgAppleAppStore from '../svg/apple_app_store';
import SvgGooglePlayStore from '../svg/google_play_store';
import styles from './home_omnia_pro.less';
import premiumProPhonesStacked from './premium_pro_phones_stacked.png';

export const HomeOmniaPro = () => {
  const { isMobile, isDesktop } = useResponsive();

  const Headline = <h3 className={styles.headline}>Unlock More Features In With PRO</h3>;

  const Paragraph = isMobile ? (
    <p>
      The Omnia app has fishing reports and gear recommendations for thousands of lakes. Upgrade to
      Omnia PRO for water clarity, current temps, bottom composition, weather and more. Free trial
      available.
    </p>
  ) : (
    <p>
      Omnia's map layers are available exclusively in the{' '}
      <OmniaLink underline to={RoutePaths.APP}>
        Omnia Fishing app
      </OmniaLink>
      . All users get access to core layers like lake info, fishing reports and cloud cover.
      Additional layers are unlocked by subscribing to a PRO membership. New subscription customers
      can sign up for PRO from the app or web. Existing Omnia Premium members can upgrade using the
      app or their Omnia Fishing account. All customers get a free 7-day trial to explore PRO tools.
    </p>
  );

  return (
    <div className={styles.wrapper}>
      <div>
        {isMobile && Headline}
        <img
          src={premiumProPhonesStacked}
          alt="mobile phones stacked on each other with PRO map layers"
          className={styles.img}
        />
      </div>
      <div>
        {isDesktop && Headline}
        {Paragraph}
        <OmniaLinkButton
          to={RoutePaths.PREMIUM_PRO}
          kind="primary"
          size="md"
          className={styles.button}
          onClick={() =>
            WebAnalytics.proButtonClicked({
              location: 'home_pro_section',
              type: 'learn_more',
              style: 'button',
            })
          }
        >
          Learn More About Omnia PRO
        </OmniaLinkButton>

        <p className={styles.download}>Download the Free Omnia Fishing App To Get Started</p>

        <ul className={styles.appStores}>
          <li className={styles.appleStoreIcon}>
            <a target="_blank" href="https://omnia.fish/apple">
              <SvgAppleAppStore width={280} height={80} />
            </a>
          </li>
          <li className={styles.googleplayIcon}>
            <a target="_blank" href="https://omnia.fish/google">
              <SvgGooglePlayStore width={280} height={80} />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};
