import { EnvironmentFilled } from '@ant-design/icons';
import classNames from 'classnames';
import React from 'react';
import styles from './badges.less';

interface NearYouBadgeProps {
  onClick?: () => void;
  className?: string;
}

export const NearYouBadge = (props: NearYouBadgeProps) => {
  const { onClick, className, ...otherProps } = props;
  return (
    <span
      {...otherProps} // otherProps is used by ant to pass things for popovers and tooltips
      className={classNames(styles.badge, className, styles.badge__nearYou)}
      onClick={(e) => {
        if (onClick) {
          e.stopPropagation();
          e.preventDefault();
          onClick?.();
        }
      }}
    >
      <EnvironmentFilled className={styles.icon} />
      Near You
    </span>
  );
};
