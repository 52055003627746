export const locations = [
  {
    name: 'rapid_city_sd',
    display_name: 'Rapid City, SD',
    lat: 44.0805,
    lng: -103.231,
  },
  {
    name: 'lake_tahoe_ca',
    display_name: 'Lake Tahoe, CA',
    lat: 39.0968,
    lng: -120.0324,
  },
  {
    name: 'ithaca_ny',
    display_name: 'Ithaca, NY',
    lat: 42.4439,
    lng: -76.5019,
  },
  {
    name: 'chattanooga_tn',
    display_name: 'Chattanooga, TN',
    lat: 35.0456,
    lng: -85.3097,
  },
  {
    name: 'cincinnati_oh',
    display_name: 'Cincinnati, OH',
    lat: 39.1031,
    lng: -84.512,
  },
  {
    name: 'san_marcos_tx',
    display_name: 'San Marcos, TX',
    lat: 29.8833,
    lng: -97.9414,
  },
  {
    name: 'orlando_fl',
    display_name: 'Orlando, FL',
    lat: 28.5383,
    lng: -81.3792,
  },
  {
    name: 'jackson_mi',
    display_name: 'Jackson, MI',
    lat: 42.2459,
    lng: -84.4013,
  },
  {
    name: 'duluth_mn',
    display_name: 'Duluth, MN',
    lat: 46.7867,
    lng: -92.1005,
  },
  {
    name: 'ely_mn',
    display_name: 'Ely, MN',
    lat: 47.9032,
    lng: -91.8672,
  },
  {
    name: 'alexandria_mn',
    display_name: 'Alexandria, MN',
    lat: 45.8844,
    lng: -95.3794,
  },
  {
    name: 'madison_wi',
    display_name: 'Madison, WI',
    lat: 43.0731,
    lng: -89.4012,
  },
  {
    name: 'green_bay_wi',
    display_name: 'Green Bay, WI',
    lat: 44.5133,
    lng: -88.0133,
  },
  {
    name: 'white_bear_lake_mn',
    display_name: 'White Bear Lake, MN',
    lat: 45.0847,
    lng: -93.008,
  },
];
