import React from 'react';
import { useListAttributionUuid } from '../../hooks/use_list_attribution_uuid';
import { FishingReport } from '@omniafishing/core';
import { LINE_ATTRIBUTION } from '../../redux/cart';
import { ProductCarousel } from '../carousel/product_carousel';
import { ProductReportCard } from './product_report_card';
import styles from './recent_trending_product_reports.less';

export const RecentTrendingProductReports = ({
  fishingReports,
}: {
  fishingReports: FishingReport[];
}) => {
  const list_uuid = useListAttributionUuid();

  return (
    <>
      <div className={styles.wrapper}>
        <ol className={styles.list}>
          <ProductCarousel
            products={fishingReports.map((fr) => fr.featured_product)}
            arrows={true}
            list_id_title="Trending Hotbaits Near You"
            list_uuid={list_uuid}
            additionalKeyProps={fishingReports.map((fr) => fr.id).join('')}
          >
            {fishingReports.map((fr, i) => (
              <li className={styles.item} key={fr.featured_product.sku + fr.id}>
                <ProductReportCard
                  product={fr.featured_product}
                  fishingReport={fr}
                  list_uuid={list_uuid}
                  position={i}
                  lineItemAttribution={[
                    {
                      key: LINE_ATTRIBUTION.FISHING_REPORT_ID,
                      value: fr.id.toString(),
                    },
                  ]}
                />
              </li>
            ))}
          </ProductCarousel>
        </ol>
      </div>
    </>
  );
};
