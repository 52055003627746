import React from 'react';
import { OmniaUrls } from '../../lib/urls';
import { WebAnalytics } from '../../lib/web_analytics';
import { RoutePaths } from '../../routes';
import { ContentWrapper } from '../content_wrapper/content_wrapper';
import { MuxPlayer } from '../muxPlayer/muxPlayer';
import { OmniaLinkButton } from '../omnia_button/omnia_link_button';
import styles from './home_promo_videos.less';
import thumbnailOmnia from './video_posters/about_omnia.jpg';
import thumbnailPro from './video_posters/premium_pro.jpg';
import thumbnailSeth from './video_posters/seth_warehouse_tour.jpg';

export const HomePromoVideos = ({
  videoSectionIntersecting,
}: {
  videoSectionIntersecting: boolean;
}) => {
  return (
    <>
      {' '}
      <div className={styles.videos}>
        <ContentWrapper>
          <h3>Why Omnia Is Different</h3>

          <div className={styles.videoCols}>
            <div>
              {videoSectionIntersecting ? (
                <MuxPlayer
                  playbackId={'gAaTZJFzXef002kHoMxY8HF02R8HD4QRE4svgJzZ5oh5E'}
                  thumbnail={thumbnailOmnia}
                />
              ) : (
                <img
                  src={thumbnailOmnia}
                  alt="image of man in front of warehouse product bins"
                  className={styles.placeHolderImg}
                />
              )}
              <h4>What is Omnia Fishing?</h4>
              <p>
                We've introduced the shop-by-lake concept because we believe shopping for tackle and
                fishing gear should be a more personalized experience. By selecting your preferred
                lakes, we can filter and present products that align with your specific needs,
                enhancing your shopping journey.
              </p>
              <OmniaLinkButton
                to={OmniaUrls.news('what-is-omnia-fishing')}
                kind="tertiary"
                size="md"
                className={styles.videosButton}
              >
                Learn More About Omnia Fishing
              </OmniaLinkButton>
            </div>
            <div>
              {videoSectionIntersecting ? (
                <MuxPlayer
                  playbackId={'7UquS4MmtFiw7pcjTMS3zEiBHPpoCPl7iXGQfxketIU'}
                  thumbnail={thumbnailSeth}
                />
              ) : (
                <img
                  src={thumbnailSeth}
                  alt="image of Seth Feider in a suit in front of Omnia Storefront"
                  className={styles.placeHolderImg}
                />
              )}
              <h4>Tour Omnia's Massive Warehouse with Seth Feider</h4>
              <p>
                At Omnia, we offer an extensive range of fishing tackle, gear, and accessories,
                equipping you to find the precise items for your specific water body. Join Seth
                Feider on a tour to explore our inventory firsthand.
              </p>
              <OmniaLinkButton
                to={RoutePaths.BIG_SELECTION}
                kind="tertiary"
                size="md"
                className={styles.videosButton}
              >
                Learn More About Our Huge Inventory
              </OmniaLinkButton>
            </div>
            <div>
              {videoSectionIntersecting ? (
                <MuxPlayer
                  playbackId={'y02lj0075FmtLecrJJ4rvcW02pSVxFe9D4iQnSISTIlWVU'}
                  thumbnail={thumbnailPro}
                />
              ) : (
                <img
                  src={thumbnailPro}
                  alt="man looking at omnia pro on mobile phone"
                  className={styles.placeHolderImg}
                />
              )}
              <h4>Omnia PRO</h4>
              <p>
                Get all the info you need to be prepared for a great day on your lake. Water temp,
                clarity, contours, weather and more, plus historical trends and fishing reports, all
                in one place. Available for thousands of US waterbodies, with more to come, in the
                Omnia Fishing app and our website.
              </p>
              <OmniaLinkButton
                to={RoutePaths.PREMIUM_PRO}
                kind="primary"
                size="md"
                className={styles.videosButton}
                onClick={() =>
                  WebAnalytics.proButtonClicked({
                    location: 'home_video_section',
                    type: 'learn_more',
                    style: 'button',
                  })
                }
              >
                Learn More About Omnia PRO
              </OmniaLinkButton>
            </div>
          </div>
        </ContentWrapper>
      </div>
    </>
  );
};
