import { BestSeller, mockProduct, OmniaResponse, StateHotbait } from '@omniafishing/core';
import { AxiosResponse } from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import placeholderImg from '../../assets/placeholder.svg';
import { isDone, isPending, LoadingState } from '../../constants/loading_state';
import { useListAttributionUuid } from '../../hooks/use_list_attribution_uuid';
import { apiV1 } from '../../lib/api';
import getNow from '../../lib/time';
import { OmniaUrls } from '../../lib/urls';
import { WebAnalytics } from '../../lib/web_analytics';
import { getIpState } from '../../redux/geographic_location';
import { getStates } from '../../redux/reference_data';
import { ProductCarousel } from '../carousel/product_carousel';
import Loading from '../loading/loading';
import { NearYouBadge } from '../product_card/near_you_badge';
import { ProductCard } from '../product_card/product_card';
import styles from './popular_near_you.less';

interface PopularNearYouProps {
  selectedSpecies: string;
  selectedSeasonGroup: string;
}

function isHotbait(item: StateHotbait | BestSeller): item is StateHotbait {
  return (item as StateHotbait).waterbodies !== undefined;
}

export const PopularNearYou = (props: PopularNearYouProps) => {
  const { selectedSeasonGroup, selectedSpecies } = props;

  const ipStateAbbr = useSelector(getIpState);
  const states = useSelector(getStates);
  const [loadingState, setLoadingState] = useState(LoadingState.NOT_STARTED);
  const [hotbaits, setHotbaits] = useState([] as StateHotbait[]);
  const [bestSellers, setBestSellers] = useState([] as BestSeller[]);
  const lastRequestTimestamp = useRef(0);
  const list_uuid = useListAttributionUuid();
  const popularProducts = [...hotbaits, ...bestSellers].slice(0, 24) as (
    | StateHotbait
    | BestSeller
  )[];

  const popularProductsDeduped = popularProducts.reduce((acc, pp) => {
    if (!acc.some((p) => p.product.shopify_product_handle === pp.product.shopify_product_handle)) {
      acc.push(pp);
    }
    return acc;
  }, [] as (StateHotbait | BestSeller)[]);

  const ipState = states.find((s) => s.abbr.toLowerCase() === `${ipStateAbbr}`.toLowerCase());

  useEffect(() => {
    const params: Record<string, string> = {};
    if (selectedSeasonGroup) {
      params.season_group = selectedSeasonGroup;
    }
    if (selectedSpecies) {
      params.species = selectedSpecies;
    }

    setLoadingState(LoadingState.PENDING);
    const requestTimestamp = getNow();

    const req =
      ipState != null
        ? apiV1.stateBestSellersFetch(ipState.slug, params)
        : apiV1.bestSellersFetch(params);

    req.then(
      (
        response: AxiosResponse<
          OmniaResponse<{ best_sellers: BestSeller[]; hotbaits: StateHotbait[] }>
        >
      ) => {
        setLoadingState(LoadingState.DONE);

        if (lastRequestTimestamp.current < requestTimestamp) {
          setHotbaits(response.data.data.hotbaits);
          const bestSellersWithoutAccessories = response.data.data.best_sellers.filter(
            (b) => b.product.category_name !== 'accessories'
          );
          setBestSellers(bestSellersWithoutAccessories);
          lastRequestTimestamp.current = requestTimestamp;
        }
      }
    );
  }, [ipState?.slug, selectedSeasonGroup, selectedSpecies]);

  const popularItems = isDone(loadingState)
    ? popularProductsDeduped.slice(0, 6).map((h) => {
        return {
          item: h,
          skeleton: false,
        };
      })
    : [1, 2, 3, 4, 5, 6].map((i) => {
        return {
          item: {
            product: mockProduct({
              img_url: placeholderImg,
              title: 'Loading\nLoading',
              price: '0.00',
              sku: `loading-${i}`,
            }),
          },
          skeleton: true,
        };
      });

  return (
    <div className={styles.wrapper}>
      {isPending(loadingState) && <Loading text="Loading Products" className={styles.loading} />}
      <ol className={styles.list}>
        <ProductCarousel
          products={popularItems.filter((i) => !i.skeleton).map((i) => i.item.product)}
          arrows={true}
          list_id_title="Popular Products Near You"
          list_uuid={list_uuid}
        >
          {popularItems.map((popularItem, i) => (
            <li className={styles.item} key={popularItem.item.product.sku}>
              <ProductCard
                product={popularItem.item.product}
                skeleton={popularItem.skeleton}
                icon={!popularItem.skeleton && <NearYouBadge />}
                onClick={() => {
                  WebAnalytics.homepageProductClick(
                    '[homepage].(popular_products_near_you)',
                    popularItem.item.product
                  );
                }}
                reportedOnLake={
                  isHotbait(popularItem.item) ? popularItem.item.waterbodies[0] : null
                }
                onReportedOnLakeClick={() => {
                  WebAnalytics.homepageProductClick(
                    '[homepage].(popular_products_near_you)',
                    popularItem.item.product
                  );
                  WebAnalytics.homepageWaterbodyClick(
                    '[homepage].(popular_products_for_lake)',
                    OmniaUrls.waterbody((popularItem.item as StateHotbait).waterbodies[0])
                  );
                }}
                list_uuid={list_uuid}
                position={i}
              />
            </li>
          ))}
        </ProductCarousel>
      </ol>
    </div>
  );
};
