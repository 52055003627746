import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import loadable from '@loadable/component';
import { Waterbody, Waypoint } from '@omniafishing/core';
import { useDrag } from '@use-gesture/react';
import { noop } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDimensions } from '../../hooks/use_dimensions';
import { useResponsive } from '../../hooks/use_responsive';
import { fitBounds } from '../../lib/fit_bounds';
import { RoutePaths } from '../../routes';
import {
  BASE_LAYER,
  CORE_LAYER,
  LAYER_TYPES,
  MapLayerState,
  OVERLAY_LAYER,
  POINTS_OF_INTEREST_LAYER,
  WEATHER_LAYER,
} from '../map/map_types';
import { OmniaLinkButton } from '../omnia_button/omnia_link_button';
import SvgLogo from '../svg/logo';
import SvgOmniaProLogo from '../svg/omnia_pro_logo';

const LoadableMap = loadable(
  () => import(/* webpackChunkName: "map-omnia_map" */ '../../components/map/omnia_map')
);

interface MapCompareProProps {
  ipState: string;
}

const usStateAbbreviations = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
];

const freeMapLayerState: MapLayerState = {
  [LAYER_TYPES.BASE]: BASE_LAYER.SATELLITE,
  [LAYER_TYPES.OVERLAY]: [OVERLAY_LAYER.PINS],
  [LAYER_TYPES.CORE]: undefined,
  [LAYER_TYPES.WEATHER]: [],
  [LAYER_TYPES.POINTS_OF_INTEREST]: [
    POINTS_OF_INTEREST_LAYER.WAYPOINTS,
    POINTS_OF_INTEREST_LAYER.BOAT_LANDINGS,
  ],
};

const proMapLayerState: MapLayerState = {
  [LAYER_TYPES.BASE]: BASE_LAYER.SATELLITE,
  [LAYER_TYPES.OVERLAY]: [OVERLAY_LAYER.PINS],
  [LAYER_TYPES.CORE]: CORE_LAYER.CONTOUR_DEPTH,
  [LAYER_TYPES.WEATHER]: [WEATHER_LAYER.WIND],
  [LAYER_TYPES.POINTS_OF_INTEREST]: [
    POINTS_OF_INTEREST_LAYER.WAYPOINTS,
    POINTS_OF_INTEREST_LAYER.BOAT_LANDINGS,
  ],
};

export const MapComparePro = (props: MapCompareProProps) => {
  const { ipState } = props;

  const { isMobile, isDesktop } = useResponsive();
  const usState = usStateAbbreviations.includes(ipState?.toUpperCase())
    ? ipState.toUpperCase()
    : 'MN';

  const [mapViewport, setMapViewport] = useState<{
    longitude: number;
    latitude: number;
    zoom: number;
  }>();
  const [mapData, setMapData] = useState<{
    markers: Waterbody[];
    waypoints: Waypoint[];
    bbox: Pick<Waterbody, 'bbox'>;
  }>();

  const [measureRef, measureBounds] = useDimensions({
    onResize: true,
    onElementResize: true,
  });

  useEffect(() => {
    if (measureBounds.width && !mapViewport) {
      fetch(`/${usState.toUpperCase()}.json`)
        .then((response) => response.json())
        .then((json) => {
          setMapData(json);
          const { bbox } = json;

          const bboxMidPoint = {
            lng: (bbox[0][0] + bbox[2][0]) / 2,
            lat: (bbox[0][1] + bbox[2][1]) / 2,
          };

          const z =
            measureBounds.width && bbox
              ? fitBounds(
                  [bbox[0], bbox[2]],
                  {
                    width: measureBounds.width,
                    height: measureBounds.height,
                  },
                  {
                    top: 40,
                    left: 20,
                    bottom: 20,
                    right: 20,
                  }
                ).zoom
              : 13;

          setMapViewport({
            longitude: bboxMidPoint.lng,
            latitude: bboxMidPoint.lat,
            zoom: z,
          });
        });
    }
  }, [usState, measureBounds.width]);

  const [proMapWidthPosition, setProMapWidthPosition] = useState<number | null>(null);
  const posRef = React.useRef(0);

  const bind = useDrag(({ first, movement: [mx, my] }) => {
    if (first) {
      posRef.current = proMapWidthPosition;
    }
    setProMapWidthPosition((prev) => {
      const newPos = posRef.current + mx;
      if (newPos < 0) {
        return 0;
      }
      if (newPos > measureBounds.width) {
        return measureBounds.width;
      }
      return newPos;
    });
  }, {});

  const Button = (
    <div className="sm:pt-6">
      <OmniaLinkButton kind="primary" size="lg" to={RoutePaths.PREMIUM_PRO} block={isMobile}>
        Explore Omnia PRO
      </OmniaLinkButton>
    </div>
  );

  return (
    <div className="flex gap-6 flex-col sm:flex-row">
      <div className="sm:w-1/2 sm:flex sm:justify-center sm:items-center">
        <div className="sm:w-3/4">
          <h2 className="text-2xl sm:text-3xl mb-3 font-extrabold">
            Catch More Fish with Omnia PRO:{' '}
            <span className="font-light">
              See What You're Missing on{' '}
              {mapData?.markers?.length ? mapData.markers[0]?.primary_name : '...'}
            </span>
          </h2>
          <p className="text-base">
            Slide to compare Omnia free maps with PRO layers. Unlock wind, depth, temperature data,
            and more for a fully-informed fishing experience.
          </p>
          {isDesktop && Button}
        </div>
      </div>
      <div className="sm:w-1/2">
        <div className="touch-pan-x">
          <div
            className="h-[400px] relative select-none rounded-xl overflow-hidden"
            ref={measureRef}
          >
            {measureBounds.width != null && mapViewport != null && (
              <>
                <div className={'absolute left-0 top-0 bottom-0 right-0'}>
                  <div className={'bg-stone-300  h-8 w-full absolute top-0 left-0  z-10'}>
                    <div className="absolute left-1/4 -translate-x-1/2 top-1/2 -translate-y-1/2 flex items-baseline gap-1">
                      <div className="w-[60px]">
                        <SvgLogo />
                      </div>
                      <span className="border-l border-blue h-5 translate-y-1" />
                      <span className="font-thin font-termina text-base">free</span>
                    </div>
                  </div>
                  <LoadableMap
                    restrictAllMovement
                    height={measureBounds.height}
                    loadInteractiveImages
                    mapLayerState={freeMapLayerState}
                    markers={mapData.markers}
                    maxBounds={null}
                    navControl={false}
                    onViewportChange={noop}
                    usePreciseLocation={false}
                    viewport={mapViewport}
                    waypoints={mapData.waypoints}
                    width={measureBounds.width}
                  />
                </div>
                <div
                  className={'absolute top-0 right-0 bottom-0 left-0 z-20'}
                  style={{
                    clipPath: `inset(0 0 0 ${proMapWidthPosition}px)`,
                  }}
                >
                  <div className={'bg-blue flex  h-8 w-full absolute top-0 left-0 z-10'}>
                    <div className="absolute left-3/4 -translate-x-1/2 top-1/2 -translate-y-1/2">
                      <SvgOmniaProLogo width={120} />
                    </div>
                  </div>

                  <LoadableMap
                    restrictAllMovement
                    height={measureBounds.height}
                    loadInteractiveImages
                    mapLayerState={proMapLayerState}
                    markers={mapData.markers}
                    maxBounds={null}
                    navControl={false}
                    onLoad={() => {
                      setProMapWidthPosition(measureBounds.width / 2);
                    }}
                    onViewportChange={noop}
                    viewport={mapViewport}
                    waypoints={mapData.waypoints}
                    width={measureBounds.width}
                  />
                </div>
                {proMapWidthPosition != null && (
                  <>
                    <div
                      className="w-0 h-full absolute border-l border-2 border-gray-200 top-0 z-20 flex items-center justify-center select-none touch-pan-x cursor-grab -translate-x-0.5"
                      style={{
                        left: `${proMapWidthPosition}px`,
                      }}
                      {...bind()}
                    >
                      <div className="rounded-full w-16 h-16 bg-white flex-grow-0 flex-shrink-0 flex items-center justify-center text-2xl">
                        <LeftOutlined />
                        <RightOutlined />
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {isMobile && Button}
    </div>
  );
};
