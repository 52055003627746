import * as React from 'react';

const SvgOmniaProLogo = (props: React.SVGAttributes<SVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={108}
    height={15}
    viewBox="0 0 108 15"
    fill="none"
    {...props}
  >
    <g fill="#fff" clipPath="url(#omnia_pro_logo_svg__a)">
      <path
        fillRule="evenodd"
        d="M7.648 1.38H3.42C1.53 1.38 0 2.88 0 4.734V8.01l3.117-1.865V4.79c0-.483.398-.875.891-.875H7.06c.492 0 .892.391.892.875v6.276a.883.883 0 0 1-.892.874H3.953c-1.302 0-1.077-1.327-.658-1.893l.896.454c-.194-1.117.122-3.232.562-4.471C2.078 7.818 0 9.242 0 11.586c0 1.462 1.122 2.886 3.12 2.886h4.528c1.888 0 3.42-1.501 3.42-3.354V4.734c0-1.853-1.531-3.354-3.42-3.354M42.73 14.472h3.116V1.38h-3.116zM37.528 1.38H30.57v13.092h3.117V3.914h3.251c.492 0 .892.391.892.875v9.684h3.116V4.734c0-1.853-1.53-3.354-3.42-3.354m-12.159 0H12.851v13.092h3.117V3.914h2.402c.492 0 .892.391.892.875v9.684h3.116V3.914h2.401c.492 0 .892.391.892.875v9.684h3.117V4.734c0-1.852-1.53-3.354-3.42-3.354m29.726 10.56H51.39a.882.882 0 0 1-.892-.875V9.696c0-.482.4-.874.892-.874h3.705zm-.303-10.56h-6.45v2.534h5.862c.493 0 .891.392.891.875v1.499H50.8c-1.888 0-3.42 1.501-3.42 3.354v1.476c0 1.853 1.531 3.354 3.42 3.354h7.412V4.735c0-1.853-1.53-3.354-3.42-3.354z"
        clipRule="evenodd"
      />
      <path d="M75.026 5.542c0 2.632-2.051 4.174-5.139 4.174h-7.362v4.814h-1.61V1.367h8.971c3.086 0 5.138 1.542 5.138 4.175zm-1.65 0c0-1.843-1.418-2.708-3.603-2.708h-7.247v5.415h7.247c2.185 0 3.604-.865 3.604-2.707M89 14.53l-4.448-5.04H78.17v5.04h-1.63V1.367h9.011c2.99 0 5.138 1.467 5.138 4.061 0 2.294-1.687 3.724-4.16 4.006l4.581 5.096zm-3.565-6.506c2.166 0 3.603-.809 3.603-2.595 0-1.785-1.437-2.594-3.603-2.594h-7.286v5.19zM91.666 7.949c0-4.288 3.067-6.976 8.167-6.976 5.099 0 8.166 2.689 8.166 6.976 0 4.286-3.067 6.975-8.166 6.975-5.1 0-8.167-2.689-8.167-6.975m14.686 0c0-3.535-2.569-5.51-6.518-5.51-3.95 0-6.518 1.975-6.518 5.51 0 3.534 2.568 5.509 6.518 5.509 3.949 0 6.518-1.975 6.518-5.51" />
    </g>
    <defs>
      <clipPath id="omnia_pro_logo_svg__a">
        <path fill="#fff" d="M0 .973h108v13.952H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgOmniaProLogo;
