import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getImgixPath } from '../../lib/imgix';
import { WebAnalytics } from '../../lib/web_analytics';
import { getPage } from '../../redux/pages';
import styles from './home_promo_panels.less';

export const HomePromoPanels = () => {
  const page = useSelector(getPage);
  if (!page) {
    return null;
  }
  return (
    <>
      <section className={styles.promoSection}>
        <div className={styles.promoItem}>
          <Link to={page.hero_url} className={styles.promoLink}>
            <img
              src={getImgixPath(page.hero_img, { w: 435 })}
              alt=""
              width={435}
              height={435}
              loading="lazy"
              onClick={() => {
                WebAnalytics.homepageAdClick(page.hero_img, page.hero_url);
              }}
            />
            <span className={styles.hiddenText}>{page.hero_text}</span>
          </Link>
        </div>
        <div className={styles.promoItem}>
          <Link to={page.hero_2_url} className={styles.promoLink}>
            <img
              src={getImgixPath(page.hero_2_img, { w: 435 })}
              alt=""
              width={435}
              height={435}
              loading="lazy"
              onClick={() => {
                WebAnalytics.homepageAdClick(page.hero_2_img, page.hero_2_url);
              }}
            />
            <span className={styles.hiddenText}>{page.hero_2_text}</span>
          </Link>
        </div>
        <div className={styles.promoItem}>
          <Link to={page.hero_3_url} className={styles.promoLink}>
            <img
              src={getImgixPath(page.hero_3_img, { w: 435 })}
              alt=""
              width={435}
              height={435}
              loading="lazy"
              onClick={() => {
                WebAnalytics.homepageAdClick(page.hero_3_img, page.hero_3_url);
              }}
            />
            <span className={styles.hiddenText}>{page.hero_3_text}</span>
          </Link>
        </div>
      </section>

      <section className={styles.promoSection__subHero}>
        <div className={styles.promoItem__subHero__big}>
          <Link to={page.subhero_1_url} className={styles.promoLink}>
            <img
              src={getImgixPath(page.subhero_1_img, { w: 883 })}
              alt=""
              width={883}
              height={434}
              loading="lazy"
              onClick={() => {
                WebAnalytics.homepageAdClick(page.subhero_1_img, page.subhero_1_url);
              }}
            />
            <span className={styles.hiddenText}>{page.subhero_1_text}</span>
          </Link>
        </div>

        <div className={styles.promoItem__subHero__small}>
          <Link to={page.subhero_2_url} className={styles.promoLink}>
            <img
              src={getImgixPath(page.subhero_2_img, { w: 435 })}
              alt=""
              width={435}
              height={211}
              loading="lazy"
              onClick={() => {
                WebAnalytics.homepageAdClick(page.subhero_2_img, page.subhero_2_url);
              }}
            />
            <span className={styles.hiddenText}>{page.subhero_2_text}</span>
          </Link>

          <Link to={page.subhero_3_url} className={styles.promoLink}>
            <img
              src={getImgixPath(page.subhero_3_img, { w: 435 })}
              alt=""
              width={435}
              height={211}
              loading="lazy"
              onClick={() => {
                WebAnalytics.homepageAdClick(page.subhero_3_img, page.subhero_3_url);
              }}
            />
            <span className={styles.hiddenText}>{page.subhero_3_text}</span>
          </Link>
        </div>
      </section>
    </>
  );
};
