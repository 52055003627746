import { Select, Space } from 'antd';
import React, { useState } from 'react';
import { OmniaButton } from '../omnia_button/omnia_button';
import { ServerEnvs, getServerEnv } from '../../env';
import { useResponsive } from '../../hooks/use_responsive';
import { useQueryString } from '../../hooks/use_query_string';
import { HomeQueryParams } from './home';
import { locations } from './locations';

export const HomeCarouselLocationSelects = () => {
  const isStagingOrLocal =
    getServerEnv() === ServerEnvs.STAGE || getServerEnv() === ServerEnvs.LOCAL;
  const { isDesktop } = useResponsive();

  const [selectsVisible, setSelectsVisible] = useState(true);
  const { getCurrentQuery, replaceQueryString } = useQueryString();
  const currentQuery = getCurrentQuery<HomeQueryParams>();

  const showSelects = currentQuery.show_location_selects;
  return (
    <>
      {isStagingOrLocal && showSelects && (
        <Space wrap style={{ paddingTop: '50px', display: selectsVisible ? 'flex' : 'none' }}>
          <Space direction={isDesktop ? 'horizontal' : 'vertical'}>
            <strong>Model</strong>
            <Select
              style={{ minWidth: 240 }}
              options={[
                { label: 'Most Recent', value: 'most_recent' },
                { label: 'Nearest', value: 'nearest' },
                {
                  label: 'Distance Recency Blend 1D/1R',
                  value: 'distance_recency_blend_d1_r1',
                },
                {
                  label: 'Distance Recency Blend 1.5D/1R',
                  value: 'distance_recency_blend_d3_r2',
                },
                {
                  label: 'Distance Recency Blend 1D/1.5R',
                  value: 'distance_recency_blend_d2_r3',
                },
                {
                  label: 'Distance Recency Blend 1D/2R',
                  value: 'distance_recency_blend_d1_r2',
                },
                {
                  label: 'Distance Recency Blend 2D/1R',
                  value: 'distance_recency_blend_d2_r1',
                },
              ]}
              value={currentQuery.trending_order}
              onSelect={(v) => replaceQueryString({ trending_order: v })}
            />
            <strong>Location</strong>
            <Select
              style={{ minWidth: 240 }}
              defaultValue={''}
              onChange={(val) => {
                const locationToSet = locations.find((l) => val === l.name);
                if (!val) {
                  replaceQueryString({
                    lat: '',
                    lng: '',
                  });
                  return;
                }
                replaceQueryString({
                  lat: locationToSet.lat,
                  lng: locationToSet.lng,
                });
              }}
            >
              <Select.Option value="" key="ip">
                Your IP LAT / LNG
              </Select.Option>
              {locations.map((l) => (
                <Select.Option value={l.name} key={l.name}>
                  {l.display_name}
                </Select.Option>
              ))}
            </Select>
            <OmniaButton onClick={() => setSelectsVisible(false)} kind="tertiary" size="md">
              Hide Selects
            </OmniaButton>
          </Space>
        </Space>
      )}
    </>
  );
};
