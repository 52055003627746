import React from 'react';

export const SVGBackgroundClipPathDesktop = (
  <svg height="0" width="0" aria-hidden="true" focusable="false">
    <defs>
      <clipPath id="clip" clipPathUnits="objectBoundingBox">
        <path d="M -0.036 0 L -0.035 0.204 Q 0.209 -0.077 0.482 0.335 T 1.046 0.345 L 1.047 -0.003 L -0.036 -0.001" />
      </clipPath>
    </defs>
  </svg>
);

export const SVGBackgroundClipPathMobile = (
  <svg height="0" width="0" aria-hidden="true" focusable="false">
    <defs>
      <clipPath id="clipTwo" clipPathUnits="objectBoundingBox">
        <path d="M -0.036 0 L -0.035 0.204 Q 0.196 0.104 0.495 0.312 T 1.05 0.304 L 1.047 -0.003 L -0.036 -0.001" />
      </clipPath>
    </defs>
  </svg>
);
