import { AmbassadorMini, UserPrivate } from '@omniafishing/core';
import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import { NAME_FALLBACK } from '../../lib/user';
import { RoutePaths } from '../../routes';
import { UserProfileImg } from '../user_profile_img/user_profile_img';
import styles from './user_profile_card.less';

interface Props {
  profile: AmbassadorMini | UserPrivate;
  onClick?: () => void;
  displayRole?: boolean;
  className?: string;
}

export const UserProfileCard = (props: Props) => {
  const { profile, onClick, displayRole = true, className } = props;
  const { slug, image, role, full_name } = profile;

  return (
    <Link
      to={`${RoutePaths.USERS}/${slug}`}
      className={classNames(styles.link, className)}
      onClick={() => {
        onClick?.();
      }}
    >
      <div className={styles.img}>
        <UserProfileImg image={image} size={null} imageSize={200} />
      </div>
      {displayRole && (
        <div
          className={classNames(styles.role, {
            [styles.bronze]: role === 'ambassador_bronze',
            [styles.silver]: role === 'ambassador_silver',
            [styles.gold]: role === 'ambassador_gold',
            [styles.blue]: role === 'ambassador_blue',
          })}
        ></div>
      )}
      <span className={styles.name}>{full_name || NAME_FALLBACK}</span>
    </Link>
  );
};
