import { ContentWrapper } from '../content_wrapper/content_wrapper';
import { hasAccessToken } from '../../redux/auth';
import { OmniaLink } from '../omnia_link/omnia_link';
import { RightOutlined } from '@ant-design/icons';
import { RoutePaths } from '../../routes';
import { useHistory } from 'react-router';
import { useResponsive } from '../../hooks/use_responsive';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import React from 'react';
import styles from './cta_banners.less';

export const CTABanners = () => {
  const { isDesktop } = useResponsive();
  const isLoggedIn = useSelector(hasAccessToken);
  const history = useHistory();

  return (
    <ContentWrapper>
      <section
        className={classNames(styles.mainCTASection, {
          [styles.mainCTASection__loggedIn]: isLoggedIn,
        })}
      >
        {isLoggedIn && (
          <OmniaLink to={RoutePaths.MAP} className={styles.cta__map}>
            <p className={styles.ctaTitle}>Shop By Lake</p>
            <span
              onClick={() => history.push(RoutePaths.MAP)}
              className={styles.linkButton}
              role="button"
            >
              {isDesktop && 'Get Started &'} Find A Lake{' '}
              <RightOutlined style={{ fontSize: '14px' }} />
            </span>
          </OmniaLink>
        )}
        <OmniaLink to={RoutePaths.BEST_SELLERS} className={styles.cta}>
          <p className={styles.ctaTitle}>Best Sellers</p>
          <span
            onClick={() => history.push(RoutePaths.BEST_SELLERS)}
            className={styles.linkButton}
            role="button"
          >
            {isDesktop && 'View'} Best Sellers <RightOutlined />
          </span>
        </OmniaLink>
        <OmniaLink to={RoutePaths.NEW} className={styles.cta}>
          <p className={styles.ctaTitle}>New Arrivals</p>
          <span
            onClick={() => history.push(RoutePaths.NEW)}
            className={styles.linkButton}
            role="button"
          >
            {isDesktop && 'View'} New Products <RightOutlined />
          </span>
        </OmniaLink>
      </section>
    </ContentWrapper>
  );
};
