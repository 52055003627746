import { Select } from 'antd';
import React from 'react';
import { OmniaLinkButton } from '../omnia_button/omnia_link_button';
import { RoutePaths } from '../../routes';
import { OmniaButton } from '../omnia_button/omnia_button';
import { USMap } from '../us_map/us_map';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { getIpState } from '../../redux/geographic_location';
import { OmniaUrls } from '../../lib/urls';
import { getStatesAlphabetical } from '../../redux/reference_data';
import styles from './us_states_map_select.less';

export const USStatesMapSelect = () => {
  const states = useSelector(getStatesAlphabetical);
  const history = useHistory();
  const ipStateAbbr = useSelector(getIpState);

  const mapHandler = (event: React.MouseEvent) => {
    const state = states.find((s) => s.abbr === (event.target as HTMLElement).dataset.name);
    if (state) {
      history.push(OmniaUrls.state(state));
    }
  };

  const selectHandler = (slug: string) => {
    const state = states.find((s) => s.slug === slug);
    if (state) {
      history.push(OmniaUrls.state(state));
    }
  };

  const ipState =
    ipStateAbbr && states.find((state) => state.abbr.toLowerCase() === ipStateAbbr.toLowerCase());

  return (
    <div className={styles.mapContainer}>
      <div className={styles.miniForm}>
        <Select
          placeholder={'Choose a State'}
          defaultValue={ipState?.name ?? undefined}
          options={states.map((state) => {
            return { label: state.name, value: state.slug };
          })}
          onChange={selectHandler}
          dropdownMatchSelectWidth={false}
          showSearch
          filterOption={(input, option) =>
            String(option.label).toLowerCase().includes(input.toLowerCase())
          }
        />
        {ipState ? (
          <OmniaLinkButton
            kind="secondary"
            size="md"
            fontSize={14}
            to={`${RoutePaths.STATES}/${ipState.slug}`}
          >
            View State
          </OmniaLinkButton>
        ) : (
          <OmniaButton kind="secondary" size="md" fontSize={14}>
            View State
          </OmniaButton>
        )}
      </div>

      <div className={styles.map}>
        <USMap onClick={mapHandler} />
      </div>
    </div>
  );
};
