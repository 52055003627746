import * as React from 'react';

const SvgLogo = (props: React.SVGAttributes<SVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" viewBox="0 0 494 114" {...props}>
    <g fill="currentColor" fill-rule="evenodd">
      <path d="M65.166.488H29.315C13.3.488.318 13.47.318 29.486v28.318l26.433-16.117V29.953a7.556 7.556 0 017.557-7.556h25.867a7.56 7.56 0 017.56 7.558v54.259a7.557 7.557 0 01-7.557 7.558H33.837c-11.04 0-9.133-11.467-5.58-16.368l7.599 3.929c-1.645-9.661 1.026-27.941 4.755-38.656C17.938 56.144.318 68.456.318 88.727c0 12.639 9.51 24.954 26.454 24.954h38.399c16.015 0 28.997-12.982 28.997-28.997V29.49C94.168 13.472 81.184.488 65.166.488M362.659 113.681h26.433V.488h-26.433zM318.541.488h-58.992v113.193h26.433V22.397h27.567a7.56 7.56 0 017.56 7.559v83.725h26.433V29.489c0-16.017-12.984-29.001-29.001-29.001M215.435.488h-106.15v113.193h26.433V22.397h20.365a7.558 7.558 0 017.559 7.558v83.726h26.433V22.397h20.364a7.56 7.56 0 017.56 7.558v83.726h26.433V29.485c0-16.015-12.982-28.997-28.997-28.997M467.5 91.775h-31.419a7.56 7.56 0 01-7.56-7.561V72.385a7.559 7.559 0 017.56-7.558H467.5v26.948zM464.932.491H410.24v21.906h49.698a7.562 7.562 0 017.562 7.562v12.958h-36.415c-16.015 0-28.997 12.983-28.997 28.997v12.76c0 16.017 12.984 28.999 29 28.998l62.845.003V29.493c0-16.018-12.984-29.002-29.001-29.002z" />
    </g>
  </svg>
);
export default SvgLogo;
