import {
  HeartOutlined,
  LogoutOutlined,
  RightOutlined,
  UnorderedListOutlined,
  UpCircleFilled,
} from '@ant-design/icons';
import { AmbassadorColorDot } from '../ambassador_color_dot/ambassador_color_dot';
import { AuthActions } from '../../redux/auth';
import { ContentWrapper } from '../content_wrapper/content_wrapper';
import { DashboardParams, DashboardTabParam } from '../dashboard/dashboard';
import { DashboardPaths, RoutePaths } from '../../routes';
import { HomePromoCarouselNew } from './home_promo_carousel';
import { isAmbassador, roleAsText } from '../../lib/user';
import { Link } from 'react-router-dom';
import { OmniaButton } from '../omnia_button/omnia_button';
import { OmniaLinkButton } from '../omnia_button/omnia_link_button';
import { omniaStringify } from '../../hooks/use_query_string';
import { useDispatch } from 'react-redux';
import { useResponsive } from '../../hooks/use_responsive';
import { UserPrivate } from '@omniafishing/core';
import { UserProfileImg } from '../user_profile_img/user_profile_img';
import classNames from 'classnames';
import React, { useState } from 'react';
import styles from './home_user_dashboard.less';
import SvgFishingReport from '../svg/fishing_report';

interface HomeUserDashboardProps {
  user: UserPrivate;
}

export const HomeUserDashboard = ({ user }: HomeUserDashboardProps) => {
  const { full_name, image, role, first_name } = user;
  const [isDashboardOpen, setIsDashboardOpen] = useState(true);
  const { isMobile, isTablet, isLargeTablet } = useResponsive();
  const dispatch = useDispatch();
  const dashboardWidgetRef = React.useRef<HTMLDivElement>(null);

  const dashboardWaterbodiesQueryString = omniaStringify({
    [DashboardParams.TAB]: DashboardTabParam.LAKES,
  });

  return (
    <ContentWrapper>
      <div className={styles.dashboardHeader}>
        <p>Welcome Back{first_name && `, ${first_name}!`}</p>
        <div className={styles.headerButtons}>
          <div className={styles.headerButtonsTop}>
            {isMobile && (
              <OmniaButton
                kind="text"
                size="md"
                onPress={() => setIsDashboardOpen(!isDashboardOpen)}
                className={styles.openDashboardButton}
              >
                <span
                  className={classNames(styles.rotateIcon, {
                    [styles.rotateIcon__180]: isDashboardOpen,
                  })}
                >
                  {' '}
                  <UpCircleFilled size={20} />
                </span>{' '}
                {isDashboardOpen ? 'Close' : 'Open'} Dashboard
              </OmniaButton>
            )}
            <OmniaButton
              size="md"
              kind="text"
              className={styles.logOutButton}
              onPress={() => dispatch(AuthActions.AUTH_LOGOUT())}
            >
              <LogoutOutlined /> Log Out
            </OmniaButton>
          </div>
        </div>
      </div>

      <div
        className={classNames(styles.mainContentWrapper, {
          [styles.mainContentWrapper__closed]: !isDashboardOpen,
        })}
        ref={dashboardWidgetRef}
      >
        <div className={styles.profileCol}>
          <div className={styles.profileHeader}>
            <div className={styles.profileChip}>
              <div className={styles.img}>
                <UserProfileImg image={image} size={70} imageSize={90} />
              </div>
              <div className={styles.userDetails}>
                <span className={styles.userName}>{full_name || 'Your Profile'}</span>
                <span className={styles.userRoleLine}>
                  {isAmbassador(user) && <AmbassadorColorDot user={user} bordered />}
                  <span className={styles.userRole}>
                    {isAmbassador(user) && roleAsText(role) + ' Ambassador'}
                  </span>
                </span>
              </div>
            </div>
            <div className={styles.buttons}>
              <OmniaLinkButton to={RoutePaths.DASHBOARD} size="lg" kind="tertiary" block>
                View Full Dashboard
              </OmniaLinkButton>
              <OmniaLinkButton to={RoutePaths.MAP} size="lg" kind="primary" block>
                Explore Map
              </OmniaLinkButton>
            </div>
          </div>
          <div className={styles.profileActionsContainer}>
            <Link to={`${RoutePaths.DASHBOARD}/${DashboardPaths.ORDERS}`}>
              <span className={styles.actionNameIcon}>
                <UnorderedListOutlined />
                Order History
              </span>
              <span className={styles.actionAndNumber}>
                <RightOutlined />
              </span>
            </Link>
            <Link to={`${RoutePaths.WISHLIST}/${user?.wishlist_hash}`}>
              <span className={styles.actionNameIcon}>
                <HeartOutlined />
                Wishlist
              </span>
              <span className={styles.actionAndNumber}>
                <RightOutlined />
              </span>
            </Link>
            <Link
              to={{
                search: dashboardWaterbodiesQueryString,
                pathname: RoutePaths.DASHBOARD,
              }}
            >
              <span className={styles.actionNameIcon}>
                <span>
                  <SvgFishingReport width={isMobile ? 15 : 19} height={isMobile ? 16 : 20} />
                </span>
                {isTablet || isLargeTablet ? 'View Your Lakes' : 'View Reports On Your Lakes'}
              </span>
              <span className={styles.actionAndNumber}>
                <RightOutlined />
              </span>
            </Link>
          </div>
        </div>
        <HomePromoCarouselNew />
      </div>
    </ContentWrapper>
  );
};
