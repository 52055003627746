import { FishingReport, SeasonGroup, Species } from '@omniafishing/core';
import { Select } from 'antd';
import classNames from 'classnames';
import React from 'react';
import { useQueryString } from '../../hooks/use_query_string';
import { useResponsive } from '../../hooks/use_responsive';
import { useUserPreferences } from '../../hooks/use_user_preferences';
import { getSeasonGroupSpeciesFishingReports } from '../../lib/fishing_reports';
import { OmniaQueryParams } from '../../lib/query_string';
import { isCurrentSeasonGroupText } from '../../lib/seasons';
import { WebAnalytics } from '../../lib/web_analytics';
import { SeasonGroupIcon } from '../season_icon/season_group_icon';
import { WaterbodyDetailQueryParams } from '../waterbody_detail/waterbody_detail';
import styles from './season_species_selects.less';

interface SeasonSpeciesSelectsProps {
  applySelectsAnimation?: boolean;
  className?: string;
  currentSeasonGroup?: SeasonGroup;
  displayIconsOnMobile?: boolean;
  fishingReports?: FishingReport[];
  showSelectsNames?: boolean;
  onApplySelectsAnimation?: (blink: boolean) => void;
  onSeasonGroupChange?: (seasonGroupName: string) => void;
  onSpeciesChange?: (speciesName: string) => void;
  seasonGroups?: SeasonGroup[];
  selectedSeasonGroup?: SeasonGroup;
  selectedSpecies: Species;
  speciesOptions?: Species[];
}
export const SeasonSpeciesSelects = (props: SeasonSpeciesSelectsProps) => {
  const {
    applySelectsAnimation,
    className,
    currentSeasonGroup,
    displayIconsOnMobile,
    fishingReports = [],
    showSelectsNames = true,
    onApplySelectsAnimation,
    onSeasonGroupChange,
    onSpeciesChange,
    seasonGroups,
    selectedSeasonGroup,
    selectedSpecies,
    speciesOptions,
  } = props;
  const { replaceQueryString, getCurrentQuery } = useQueryString();
  const currentQuery = getCurrentQuery<WaterbodyDetailQueryParams>();

  const { setUserPreferencesSpecies, setUserPreferencesSeasonGroup } = useUserPreferences();
  const { isDesktop } = useResponsive();

  const getSpeciesFRCountFilteredBySeasonGroup = (specie: Species) => {
    return getSeasonGroupSpeciesFishingReports(fishingReports, selectedSeasonGroup, specie).length;
  };

  const getSeasonGroupFRCountFilteredBySpecies = (seasonGroup: SeasonGroup) => {
    return getSeasonGroupSpeciesFishingReports(fishingReports, seasonGroup, selectedSpecies).length;
  };

  const getMobileCurrentSeasonText = (seasonGroup: SeasonGroup) => {
    return currentSeasonGroup.name === seasonGroup?.name ? ' Current' : '';
  };
  return (
    <div
      className={classNames(styles.selectsSection, className, {
        [styles.selectsSection__blink]: applySelectsAnimation,
      })}
      onAnimationEnd={() => onApplySelectsAnimation?.(false)}
    >
      {isDesktop && showSelectsNames && <p>Species</p>}
      <Select
        value={selectedSpecies.name}
        placeholder="Select a Species"
        onChange={(val: string) => {
          if (currentQuery.technique) {
            replaceQueryString({
              [OmniaQueryParams.technique]: null,
            });
          }
          replaceQueryString({
            [OmniaQueryParams.species]: val,
          });
          WebAnalytics.contentFiltered('species', val);
          setUserPreferencesSpecies(val);
          onSpeciesChange?.(val);
        }}
        dropdownStyle={{ minWidth: 256 }}
        getPopupContainer={(trigger: any) => trigger.parentNode}
        options={speciesOptions.map((s) => {
          const speciesCount = getSpeciesFRCountFilteredBySeasonGroup(s);
          return {
            label: (
              <span className={styles.selectLabel}>
                <span className={styles.selectName}>
                  <img
                    src={s.image}
                    alt={s.img_alt}
                    className={classNames(styles.selectImg, {
                      [styles.selectImg__mobile]: displayIconsOnMobile,
                    })}
                  />
                  {s.display_name}
                </span>
                <span className={styles.reportCount}>{!!speciesCount && speciesCount}</span>
              </span>
            ),
            value: s.name,
          };
        })}
      />

      {isDesktop && showSelectsNames && <p className={styles.seasonText}>Season</p>}
      <Select
        dropdownStyle={{ minWidth: 195 }}
        className={styles.seasonSelect}
        popupClassName={styles.selectDropdown}
        getPopupContainer={(trigger: any) => trigger.parentNode}
        value={selectedSeasonGroup?.name}
        dropdownMatchSelectWidth={false}
        placeholder="Select a Season"
        onChange={(val: string) => {
          if (currentQuery.technique) {
            replaceQueryString({
              [OmniaQueryParams.technique]: null,
            });
          }
          replaceQueryString({
            [OmniaQueryParams.season_group]: val,
          });
          WebAnalytics.contentFiltered('season', val);
          setUserPreferencesSeasonGroup(val);
          onSeasonGroupChange?.(val);
        }}
        options={seasonGroups.map((sg) => {
          const seasonCount = getSeasonGroupFRCountFilteredBySpecies(sg);
          return {
            label: (
              <span className={styles.selectLabel}>
                <span className={styles.seasonNameLine}>
                  <SeasonGroupIcon
                    seasonGroupName={sg.name}
                    className={classNames(styles.seasonIcon, {
                      [styles.seasonIcon__mobile]: displayIconsOnMobile,
                    })}
                  />
                  <span className={styles.seasonName}>{sg.display_name}</span>
                  <span className={styles.currentLabel}>
                    {isDesktop
                      ? isCurrentSeasonGroupText(sg, currentSeasonGroup)
                      : getMobileCurrentSeasonText(sg)}
                  </span>
                </span>
                <span className={styles.reportCount}>{!!seasonCount && seasonCount}</span>
              </span>
            ),
            value: sg.name,
          };
        })}
      />
    </div>
  );
};
